
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import './DataTable.css';
import { TextField, Button, Box, Typography } from '@mui/material';

const ITEMS_PER_PAGE = 10;

const DataTable = ({ data }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [currentItems, setCurrentItems] = useState([]);
  const [itemsPerPage] = useState(5);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [formData, setFormData] = useState({
    purpose: '',
    location: '',
    executor: '',
    position: '',
  });

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  useEffect(() => {
    const totalItems = data.length;
    setTotalPages(Math.ceil(totalItems / ITEMS_PER_PAGE));
    setCurrentItems(data.slice((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE));
  }, [data, currentPage]);

  const formatDateTime = (dateTime) => {
    const date = new Date(dateTime);
    return {
      date: date.toLocaleDateString(),
      time: date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
    };
  };

  const isValueExceedsPdk = (value, pdk) => {
    if (pdk === '' || value === 'NaN') return false;
    const pdkValue = parseFloat(pdk.replace(/,/, '.'));
    const measureValue = parseFloat(value.replace(/,/, '.'));
    return measureValue > pdkValue;
  };

  const renderTableHeader = () => {
    const headers = ['Дата', 'Время', 'Адрес', 'Координаты', ...data[0].measeres.map((measure) => measure.header), 'Действия'];
    return (
      <thead>
        <tr>
          {headers.map((header, index) => (
            <th key={index}>{header}</th>
          ))}
        </tr>
      </thead>
    );
  };

  const renderTableBody = () => {
    return (
      <tbody>
        {currentItems.map((item, index) => (
          <React.Fragment key={index}>
            <tr>
              <td>{formatDateTime(item.dateTime).date}</td>
              <td>{formatDateTime(item.dateTime).time}</td>
              <td>{item.location}</td>
              <td>{item.position || '-'}</td>
              {item.measeres.map((measure, i) => (
                <td
                  key={i}
                  className={isValueExceedsPdk(measure.value, measure.pdk) ? 'red-value' : ''}
                >
                  {measure.value}
                </td>
              ))}
              <td>
                <button className="report-button" onClick={() => handleReportClick([item])}>
                  Получить отчет
                </button>
              </td>
            </tr>
          </React.Fragment>
        ))}
      </tbody>
    );
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleReportClick = (items) => {
    console.log('Получить отчет для:', items);
    setSelectedItems(items);
    setIsModalOpen(true);
  };

  const generateReport = async () => {
    try {
      // Отправляем POST-запрос для генерации отчета
      const postResponse = await axios.post('https://api.subbnet.ru/api/generateReport', {"form": formData, "measure": selectedItems}, {
        headers: {
          'Content-Type': 'application/json',
        },
        responseType: 'json',
      });
      console.log({'form': formData, 'measure': selectedItems});

      // Получаем ссылку на скачивание файла
      const downloadUrl = postResponse.data;
      console.log(downloadUrl);

      try {
        const fileUrl = downloadUrl;
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', 'report.pdf');
        document.body.appendChild(link);
        link.click();
        link.remove();
        setIsModalOpen(false);
        setSelectedItems([]);
        setFormData([]);
      } catch (error) {
        console.error('Error generating report:', error);
      }
    } catch (error) {
      console.error('Ошибка при генерации и загрузке отчета:', error);
    }
  }
  
  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedItems([]);
    setFormData([]);
  };

  const handleGenerateReport = () => {
    if (formData.purpose && formData.location && formData.executor && formData.position) {
      generateReport(formData);
      handleModalClose();
    } else {
      console.error('Please fill out all the required fields.');
    }
  };

  return (
    <div>
      <table className="paginated-table">
        {renderTableHeader()}
        {renderTableBody()}
      </table>
      <div className="pagination">
        {Array.from({ length: totalPages }, (_, index) => index + 1).map((page) => (
          <button
            key={page}
            className={currentPage === page ? 'active' : ''}
            onClick={() => handlePageChange(page)}
          >
            {page}
          </button>
        ))}
      </div>
     <Modal
      className="modal-overlay"
      isOpen={isModalOpen}
      onRequestClose={handleModalClose}
      style={{
        content: {
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: '600px',
          maxHeight: '800px',
          width: 'fit-content',
          height: 'fit-content',
          overflow: 'auto',
          backgroundColor: '#f9f9f9',
          padding: '32px',
          borderRadius: '5px',
          border: '2px solid #dddddd',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
        },
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.2)',
        },
      }}
    >
      <Box
        component="form"
        onSubmit={(event) => {
          event.preventDefault();
          handleGenerateReport();
        }}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
        }}
      >
        <Typography variant="h4" align="center" gutterBottom>
          Сгенерировать отчет
        </Typography>
        <TextField
          label="Цель отбора"
          id="purpose"
          value={formData.purpose}
          onChange={handleInputChange}
          required
          variant="outlined"
        />
        <TextField
          label="Местоположение"
          id="location"
          value={formData.location}
          onChange={handleInputChange}
          required
          variant="outlined"
        />
        <TextField
          label="Исполнитель"
          id="executor"
          value={formData.executor}
          onChange={handleInputChange}
          required
          variant="outlined"
        />
        <TextField
          label="Должность"
          id="position"
          value={formData.position}
          onChange={handleInputChange}
          required
          variant="outlined"
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '16px',
          }}
        >
          <Button
            variant="contained"
            type="submit"
            sx={{
              backgroundColor: '#4CAF50',
              '&:hover': {
                backgroundColor: '#45a049',
              },
            }}
          >
            Сгенерировать отчет
          </Button>
          <Button
            variant="outlined"
            onClick={handleModalClose}
            sx={{
              color: '#4CAF50',
              borderColor: '#4CAF50',
              '&:hover': {
                borderColor: '#45a049',
                color: '#45a049',
              },
            }}
          >
            Отменить
          </Button>
        </Box>
      </Box>
    </Modal>
    </div>
  );
};

export default DataTable;