import React, { useState } from 'react';

const FormExample = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
  });

  const [errors, setErrors] = useState({
    name: '',
    email: '',
    password: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: '' }); // Очищаем ошибку при изменении поля
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Проверка полей формы
    let hasErrors = false;
    const newErrors = { ...errors };

    if (!formData.name) {
      newErrors.name = 'Пожалуйста, введите имя';
      hasErrors = true;
    }

    if (!formData.email) {
      newErrors.email = 'Пожалуйста, введите email';
      hasErrors = true;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Неверный формат email';
      hasErrors = true;
    }

    if (!formData.password) {
      newErrors.password = 'Пожалуйста, введите пароль';
      hasErrors = true;
    } else if (formData.password.length < 6) {
      newErrors.password = 'Пароль должен быть не менее 6 символов';
      hasErrors = true;
    }

    setErrors(newErrors);

    if (!hasErrors) {
      // Обработка успешной отправки формы
      console.log('Форма успешно отправлена:', formData);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label htmlFor="name">Имя:</label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
        />
        {errors.name && <div className="error">{errors.name}</div>}
      </div>
      <div>
        <label htmlFor="email">Email:</label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
        />
        {errors.email && <div className="error">{errors.email}</div>}
      </div>
      <div>
        <label htmlFor="password">Пароль:</label>
        <input
          type="password"
          id="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
        />
        {errors.password && <div className="error">{errors.password}</div>}
      </div>
      <button type="submit">Отправить</button>
    </form>
  );
};

export default FormExample;
