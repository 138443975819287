import React, { useState, useEffect } from 'react';
import { TextField, MenuItem, Grid } from '@material-ui/core';

const MyDateTimeField = ({ label, value, onChange }) => {
  const [selectedDate, setSelectedDate] = useState(new Date(value));
  const [selectedHour, setSelectedHour] = useState(selectedDate.getHours());
  const [selectedMinute, setSelectedMinute] = useState(Math.floor(selectedDate.getMinutes() / 20) * 20);

  useEffect(() => {
    if (onChange) {
      onChange(new Date(selectedDate));
    }
  }, [selectedDate, selectedHour, selectedMinute]);

  const handleDateChange = (event) => {
    const newDate = new Date(event.target.value);
    setSelectedDate(newDate);
    setSelectedHour(selectedHour); // newDate.getHours());
    setSelectedMinute(selectedMinute); // Math.floor(newDate.getMinutes() / 20) * 20);
  };

  const handleHourChange = (event) => {
    const newDate = new Date(selectedDate);
    newDate.setHours(event.target.value);
    setSelectedDate(newDate);
    setSelectedHour(event.target.value);
  };

  const handleMinuteChange = (event) => {
    const newDate = new Date(selectedDate);
    newDate.setMinutes(event.target.value);
    setSelectedDate(newDate);
    setSelectedMinute(event.target.value);
  };

  const minutes = Array.from({ length: 3 }, (_, i) => i * 20);

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextField
          id="date-select"
          label="Дата"
          type="date"
          value={selectedDate.toISOString().slice(0, 10)}
          onChange={handleDateChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          id="hour-select"
          label="Часы"
          select
          value={selectedHour}
          onChange={handleHourChange}
        >
          {Array.from({ length: 24 }, (_, i) => i).map((hour) => (
            <MenuItem key={hour} value={hour}>
              {hour.toString().padStart(2, '0')}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={3}>
        <TextField
          id="minute-select"
          label="Минуты"
          select
          value={selectedMinute}
          onChange={handleMinuteChange}
        >
          {minutes.map((minute) => (
            <MenuItem key={minute} value={minute}>
              {minute.toString().padStart(2, '0')}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    </Grid>
  );
};

export default MyDateTimeField;



// import React, { useState, useEffect, useCallback } from 'react';
// import { TextField, MenuItem, Grid } from '@material-ui/core';
// import throttle from 'lodash/throttle';

// const MyDateTimeField = ({ onChange }) => {
//   const [selectedDate, setSelectedDate] = useState(new Date());
//   const [selectedHour, setSelectedHour] = useState(selectedDate.getHours());
//   const [selectedMinute, setSelectedMinute] = useState(Math.floor(selectedDate.getMinutes() / 20) * 20);

//   const handleDateTimeChange = useCallback(
//     throttle(() => {
//       if (onChange) {
//         onChange(new Date(selectedDate));
//       }
//     }, 5000),
//     [selectedDate, selectedHour, selectedMinute]
//   );

//   useEffect(() => {
//     handleDateTimeChange();
//   }, [selectedDate, handleDateTimeChange]);

//   const handleDateChange = (event) => {
//     const newDate = new Date(event.target.value);
//     setSelectedDate(newDate);
//     setSelectedHour(newDate.getHours());
//     setSelectedMinute(Math.floor(newDate.getMinutes() / 20) * 20);
//   };

//   const handleHourChange = (event) => {
//     const newHour = event.target.value;
//     setSelectedHour(newHour);
//     setSelectedDate(
//       new Date(
//         selectedDate.getFullYear(),
//         selectedDate.getMonth(),
//         selectedDate.getDate(),
//         newHour,
//         selectedMinute
//       )
//     );
//   };

//   const handleMinuteChange = (event) => {
//     const newMinute = event.target.value;
//     setSelectedMinute(newMinute);
//     setSelectedDate(
//       new Date(
//         selectedDate.getFullYear(),
//         selectedDate.getMonth(),
//         selectedDate.getDate(),
//         selectedHour,
//         newMinute
//       )
//     );
//   };

//   return (
//     <Grid container spacing={2}>
//       <Grid item>
//         <TextField
//           label="Date"
//           type="date"
//           value={selectedDate.toISOString().slice(0, 10)}
//           onChange={handleDateChange}
//           InputLabelProps={{
//             shrink: true,
//           }}
//         />
//       </Grid>
//       <Grid item>
//         <TextField
//           label="Hour"
//           select
//           value={selectedHour}
//           onChange={handleHourChange}
//         >
//           {[...Array(24).keys()].map((hour) => (
//             <MenuItem key={hour} value={hour}>
//               {hour}
//             </MenuItem>
//           ))}
//         </TextField>
//       </Grid>
//       <Grid item>
//         <TextField
//           label="Minute"
//           select
//           value={selectedMinute}
//           onChange={handleMinuteChange}
//         >
//           {[0, 20, 40].map((minute) => (
//             <MenuItem key={minute} value={minute}>
//               {minute}
//             </MenuItem>
//           ))}
//         </TextField>
//       </Grid>
//     </Grid>
//   );
// };

// export default MyDateTimeField;
