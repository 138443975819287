import React, { useState, useEffect } from 'react';
import { makeStyles, Box, TextField, Checkbox, FormControlLabel, Button, Grid, MenuItem } from '@mui/material';
import axios from 'axios';
import styles from './MeasurementForm.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ru from 'date-fns/locale/ru'; // Import the Russian locale
import DateTimeField from './DateTimeField';
import CustomDateTimeLocalInput from './CustomDateTimeLocalInput';
import { Controller, useForm } from "react-hook-form";
import DateTime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';

const MeasurementForm = () => {
    const [measurementPoint, setMeasurementPoint] = useState('');
    const [startDateTime, setStartDateTime] = useState('');
    const [endDateTime, setEndDateTime] = useState('');
    const [substances, setSubstances] = useState([]);
    const [selectedSubstances, setSelectedSubstances] = useState([]);
    //   const classes = useStyles();

    useEffect(() => {
        // Загрузка списка веществ из API
        const fetchSubstances = async () => {
            try {
                const response = await axios.get('https://api.subbnet.ru/api/substanceGet');
                setSubstances(response.data);
            } catch (error) {
                console.error('Error fetching substances:', error);
            }
        };
        fetchSubstances();
    }, []);

    const { handleSubmit, formState: { errors }, getValues } = useForm();

    const onSubmit = (data) => {
        // const { name, value, type } = data.target;
        console.log("onSubmit сработал - " + data.data);
        // Проверяем, была ли выбрана дата
        if (!data.startDateTime) {
            setError("startDateTime", { message: "Это обязательное поле" });
            return;
        }

        // Здесь обрабатывайте отправку формы
        console.log(data);
    };

    const setError = (name, error) => {
        errors[name] = error;
    };

    const _handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post('https://api.subbnet.ru/api/measurementAdd', {
                measurementPoint,
                startDateTime,
                endDateTime,
                substances: selectedSubstances,
            });
            // Очистка формы после успешной отправки
            setMeasurementPoint('');
            setStartDateTime('');
            setEndDateTime('');
            setSelectedSubstances([]);
        } catch (error) {
            console.error('Error submitting measurement:', error);
        }
    };

    const handleSubstanceChange = (substance) => {
        if (selectedSubstances.includes(substance)) {
            setSelectedSubstances(selectedSubstances.filter((item) => item !== substance));
        } else {
            setSelectedSubstances([...selectedSubstances, substance]);
        }
    };

    //   const [dateTime, setDateTime] = useState('');
    //   const handleDateTimeChange = (event) => {
    //     const newDateTime = new Date(event.target.value);
    //     newDateTime.setMinutes(Math.round(newDateTime.getMinutes() / 20) * 20);
    //     setDateTime(newDateTime.toISOString().slice(0, 16));
    //   };

    const [selectedDate, setSelectedDate] = useState(null);
    const [value, setChange] = useState(null);

    const handleChange = (event) => {
        const newValue = event.target.value;

        if (/^([0-5]?[0-9]|60)$/.test(newValue)) {
            // Проверяем, что значение соответствует интервалу 20 минут
            if (newValue === '00' || newValue === '20' || newValue === '40') {
                setChange(event);
            }
        }
    };

    const [vvalue, setValue] = React.useState(null);

    const handleChange2 = (date) => {
        setValue(date.toISOString().slice(0, 16));
    };

    const [periodFrom, setPeriodFrom] = useState();
    const [periodTo, setPeriodTo] = useState();
    const getFormattedDateTime = (dateString) => {
        console.log(dateString);
        const date = new Date(dateString);
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = (Math.floor(date.getMinutes() / 20) * 20).toString().padStart(2, '0');
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}T${hours}:${minutes}`;
    };

    const handleParamChange = (event) => {
        const { name, value, type } = event.target;
        console.log("Сработал handleParamChangeDT - " + name + " - " + value + " - " + type);
        setPeriodFrom(value)
    };

    const handleParamChangeDT = (event) => {
        const { name, value, type } = event.target;
        console.log("Сработал handleParamChangeDT - " + name + " - " + value + " - " + type);
        if (name === 'periodFrom') {
            setPeriodFrom(value);
        } else if (name === 'periodTo') {
            setPeriodTo(value);
        }
    };

    const [selectedHour, setSelectedHour] = useState(0);
    const [selectedMinute, setSelectedMinute] = useState(0);
  
    const hours = Array.from({ length: 24 }, (_, i) => i);
    const minutes = Array.from({ length: 60 }, (_, i) => i);
  
    const handleHourChange = (event) => {
      setSelectedHour(event.target.value);
    };
  
    const handleMinuteChange = (event) => {
      setSelectedMinute(event.target.value);
    };

    return (
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>

            {/* <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextField
                    id="hour-select"
                    label="Hour"
                    select
                    value={selectedHour}
                    onChange={handleHourChange}
                    >
                    {hours.map((hour) => (
                        <MenuItem key={hour} value={hour}>
                        {hour.toString().padStart(2, '0')}
                        </MenuItem>
                    ))}
                    </TextField>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                    id="minute-select"
                    label="Minute"
                    select
                    value={selectedMinute}
                    onChange={handleMinuteChange}
                    >
                    {minutes.filter((minute) => minute % 15 === 0).map((minute) => (
                        <MenuItem key={minute} value={minute}>
                        {minute.toString().padStart(2, '0')}
                        </MenuItem>
                    ))}
                    </TextField>
                </Grid>
            </Grid> */}

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {/* <DateTimeField></DateTimeField> */}
                    {/* <TextField
            label="Точка измерения #"
            value={measurementPoint}
            onChange={(e) => setMeasurementPoint(e.target.value)}
            required
            className={styles.customTextField}
          /> */}
                </Grid>
                <Grid item xs={6}>
                    <DatePicker
                        name='DatePicker'
                        style={{
                            '.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-range': {
                                backgroundColor: '#4CAF50 !important',
                                color: 'white !important',
                            },
                        }}
                        selected={periodFrom} // getFormattedDateTime(periodFrom)} // getValues("startDateTime")}
                        onChange={(date) => {
                            // console.log(date)
                            setPeriodFrom(date)
                            // setError("periodFrom", null);
                            // setValue("periodFrom", date);
                        }}
                        // value={periodFrom}// getFormattedDateTime(periodFrom)}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={20}
                        dateFormat="dd.MM.yyyy HH:mm"
                        customInput={
                            <TextField
                                name="periodFrom"
                                label="Начало периода"
                                variant="outlined"
                                // error={!!errors.startDateTime}
                                // helperText={errors.startDateTime?.message}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        }
                        locale={ru}
                        className="custom-datepicker"
                    />
                    {/* <DatePicker
                        selected={startDateTime}
                        onChange={(date) => setStartDateTime(date)}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={20}
                        dateFormat="dd.MM.yyyy HH:mm"
                        customInput={
                            <TextField
                                label="Начало периода"
                                variant="outlined"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />}
                        locale={ru}
                        className="custom-datepicker"
                    /> */}
                </Grid>
                <Grid item xs={6}>
                <DatePicker
                        name='DatePicker'
                        style={{
                            '.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-range': {
                                backgroundColor: '#4CAF50 !important',
                                color: 'white !important',
                            },
                        }}
                        selected={periodTo} // getFormattedDateTime(periodFrom)} // getValues("startDateTime")}
                        onChange={(date) => {
                            // console.log(date)
                            setPeriodTo(date)
                            // setError("periodFrom", null);
                            // setValue("periodFrom", date);
                        }}
                        // value={periodFrom}// getFormattedDateTime(periodFrom)}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={20}
                        dateFormat="dd.MM.yyyy HH:mm"
                        customInput={
                            <TextField
                                name="periodTo"
                                label="Начало периода"
                                variant="outlined"
                                // error={!!errors.startDateTime}
                                // helperText={errors.startDateTime?.message}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        }
                        locale={ru}
                        className="custom-datepicker"
                    />                   
                    {/* <TextField
                        // name="periodFrom"
                        name="periodTo"
                        label="Окончание"
                        type="datetime-local"
                        value={getFormattedDateTime(periodTo)}
                        // size="small"
                        onChange={handleParamChangeDT}
                        // value={endDateTime}
                        // variant="filled"
                        // onChange={(e) => setEndDateTime(e.target.value)}
                        required
                        className={styles.customTextField}
                        step={1200}
                    /> */}
                        {/* <input
                        id="my-datetime-field"
                        name="my-datetime-field"
                        type="datetime-local"
                        value={value}
                        onChange={handleChange}
                        step={1200} // Шаг в секундах (15 минут)
                        /> */}

{/* <DateTime
      id="my-datetime-field"
      name="my-datetime-field"
      value={value}
      onChange={handleChange}
      timeStep={15}
      dateFormat="YYYY-MM-DD"
      timeFormat="HH:mm"
    /> */}
                </Grid>
                <Grid item xs={12}>
                    <Box>
                        <div>Вещества:</div>
                        {substances.map((substance) => (
                            <FormControlLabel
                                key={substance.id}
                                control={
                                    <Checkbox
                                        checked={selectedSubstances.includes(substance.id)}
                                        onChange={() => handleSubstanceChange(substance.id)}
                                        style={{ color: "#45a049" }}
                                    />
                                }
                                label={substance.name}
                            />
                        ))}
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Button type="submit" variant="contained" style={{ backgroundColor: "#45a049", "&:hover": { backgroundColor: "#3c8a40" } }}>
                        Отправить
                    </Button>
                </Grid>
            </Grid>
        </Box>

        
    );
};

export default MeasurementForm;
